<template>
	<div class="page" >
		<div style="width:750px; margin:0 auto;padding-top:100px">
		<table class="printtable" v-if="baodan">
			<tr>
				<td>凭证编号：</td>
				<td>{{baodan.pay_order_num}}</td>
				<td>缴费金额:</td>
				<td>￥{{baodan.total_money}}.00</td>
				<td>缴费日期:</td>
				<td>{{baodan.pay_time}}</td>
			</tr>
			<tr>
				<td>学生姓名:</td>
				<td>{{baodan.stu_name}}</td>
				<td>证件类型:</td>
				<td>{{baodan.id_type}}</td>
				<td>证件号码:</td>
				<td>{{baodan.id_number}}</td>
			</tr>
			<tr>
				<td>保险期限:</td>
				<td colspan="5">{{baodan.start_time}} 至 {{baodan.end_time}}</td>
				
			</tr>
		</table>
	</div>
		<div id="loadok" class="loadok" v-if="dataok"></div>


	</div>
</template>

<script>
	

	export default {
		components: {
			


		},
		data() {
			return {
				bd_id:0,
				dataok:false,
				baodan:null
			}
		},
		mounted() {
			this.bd_id = this.$route.query.id
			this.getBaodan()
			
			
		},
		methods: {
			getBaodan(){
				this.$http.post("/api/get_baodan_info",{id:this.bd_id}).then(res=>{
					if(res.data.id_type==1){
						res.data.id_type="身份证"
					}else if(res.data.id_type==2){
						res.data.id_type="港澳台证"
					}else if(res.data.id_type==3){
						res.data.id_type="护照"
					}
					this.baodan = res.data
						this.dataok = true
				})
			}
			

		}
	}
</script>
<style scoped>
	
	.printtable,
	.printtable tr th,
	.printtable tr td {
		border: 1px solid #000 !important;
		border-collapse: collapse;
		-webkit-print-color-adjust: exact;
		-moz-print-color-adjust: exact;
		color-adjust: exact;
		padding: 10px;
	}
	.printtable{ border: 2px solid #000 !important; margin: 0 auto; }


	
</style>
